!((document, Drupal, $) => {
  'use strict';

  /**
  * Setup and attach the Form behaviors.
  *
  * @type {Drupal~behavior}
  */

  /**
   * Adds float label and hide show functionality for form items.
   */
  Drupal.behaviors.formItem = {

    attach: function (context) {
      this.floatLabelInit(this, context);
    },

    floatLabelInit: function (behavior, context) {
      const types = '[type="text"],[type="search"],[type="password"],[type="email"],[type="tel"],[type="url"],textarea'; // eslint-disable-line max-len

      const $formElement = $('.form-item:has(' + types + ')', context);

      if (!$formElement.length) {
        return;
      }

      $formElement
        // Identify this element as having a floated label.
        .addClass('form-item--floated-label')
        // Get the input.
        .find('input, textarea')
        // Check to see if there's any value in the inputs
        // by default and apply a class if so.
        .each(function () {
          behavior.floatLabel($(this));
        })
        // If the user focuses or blurs the input check if the
        // input now has a value. If so add a class.
        .on('focus input blur', function () {
          behavior.floatLabel($(this));
        });
    },

    // If the input contains any value, add the class. If not
    // remove it.
    floatLabel: function ($formElement) {
      const $formLabel = $formElement.closest('.form-item').find('label');
      if ($formElement.val() !== '') {
        $formLabel.addClass('floated');
      }
      else {
        $formLabel.removeClass('floated');
        // organization populated based on code input...
        $('.form-item-field-school-or-organization-und-0-value label')
          .addClass('floated');
      }
    },
  };

})(document, Drupal, jQuery);
